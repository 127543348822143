import React, { useEffect, useState } from "react";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";
import { getCompanyEmployees } from "../axios/Employees";
import { fetchCompanies } from "../axios/Companies";
import { getCompanyEmployeesReport } from "../axios/Reports";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const PrintOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function PrintCompanyEmployeesModal({
  isOpen,
  handleClose,
  reportInfo,
  setReportInfo,
}) {
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [onlyDrivers, setOnlyDrivers] = useState(false);

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      setAllCompanies(companies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleCancelOrPrint = (e) => {
    if (e.target.name === "print") {
      getReport();
    }
    handleClose();
    setReportInfo({});
    setSelectedCompany("");
    setOnlyDrivers(false);
  };

  const getReport = async () => {
    try {
      const data = await getCompanyEmployees(selectedCompany);
      const currentEmployees = data.filter(
        (employee) => employee.isHidden !== true && employee.isHidden !== "true"
      );

      const filteredEmployees = onlyDrivers
        ? currentEmployees.filter((employee) => employee.isDriver) // Assuming 'isDriver' is a property of the employee object
        : currentEmployees;

      // Prepare reportInfo structure
      const reportData = {
        company: {
          companyName: reportInfo.companyName,
          companyId: reportInfo.companyId,
        },
        employees: filteredEmployees,
        onlyDrivers,
      };

      // Call the function to get the company employees report with the prepared data
      await getCompanyEmployeesReport(reportData);
    } catch (error) {
      console.error("Error getting company employees:", error);
    }
  };

  useEffect(() => {
    fetchCompaniesAndSave();
  }, []);

  useEffect(() => {
    if (reportInfo.companyId) {
      setSelectedCompany(reportInfo.companyId);
    }
  }, [reportInfo]);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <h2 style={{ textAlign: "center" }}>Company Employees Report</h2>
          <div className="inputContainer">
            <div className="formRow">
              <div className="formCell">Select Company</div>
              <Select
                size="small"
                fullWidth
                name="companyName"
                sx={textFieldStyle}
                value={selectedCompany}
                onChange={(e) => {
                  const selected = allCompanies.find(
                    (company) => company._id === e.target.value
                  );
                  setReportInfo({
                    companyName: selected.companyName,
                    companyId: selected._id,
                  });
                  setSelectedCompany(selected._id);
                }}
              >
                {allCompanies.map(({ companyName, _id }) => (
                  <MenuItem key={_id} value={_id}>
                    {companyName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="formRow">
              <div className="formCell">Only Drivers?</div>
              <Select
                size="small"
                fullWidth
                name="recordSet"
                sx={textFieldStyle}
                value={onlyDrivers}
                onChange={(e) => setOnlyDrivers(e.target.value === "true")}
              >
                <MenuItem value={true.toString()}>Yes</MenuItem>
                <MenuItem value={false.toString()}>No</MenuItem>
              </Select>
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <PrintOrCancel
                onClick={(e) => {
                  handleCancelOrPrint(e);
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </PrintOrCancel>
              <PrintOrCancel
                onClick={(e) => {
                  handleCancelOrPrint(e);
                }}
                name="print"
                variant="contained"
              >
                Print
              </PrintOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
